import React from 'react'
import './header.css'
import ai from '../../assets/ai.png'
import bannerImg from '../../assets/banner-img.png'

const Header = () => {
    return (
        <div className='ficiali__header section__padding-2' id='home'>
            <div className='ficiali__header-content'>
                <h1 className='gradient__text'>The AI Revolution</h1>
                <p>
                Ficiali is the fastest growing software company worldwide. We understand the value of technology. Our AI based solutions help to solve complex problems in everyday life.
                    A group of experts help you make innovative solutions to new technology at Ficiali.
                </p>
                <p>Let's invest in AI for a better future. </p>
            </div>
            <div className='ficiali__header-image'>
                    <img src={ai} alt='ai' />
            </div>
        </div>
    )
}

export default Header
