import React from "react";
import './contactForm.css'


const ContactForm = () => {

  return (
    <form 
      action="https://public.herotofu.com/v1/fcddb590-5ce4-11ec-b63a-19467ff46d66" 
      method="POST"
      id="contact-form"
    >
        <label for="name">Your Name</label>
        <input  name="Name" id="name" type="text" required />

        <label for="email">Your Email</label>
        <input name="Email" id="email" type="email" required  />

        <label for="message">Your Message</label>
        <textarea name="Message" id="message" type="text" required rows={6} />
        <input type="submit" value="Send Message" />  
    </form>
  );
};

export default ContactForm;