import React from 'react'
import { Feature } from '../../components'
import './products.css'

const productsData = [
    {
        title: 'Custom Object Detection',
        text: 'Identify multiple objects in images and videos to automate product tagging or product listing. Our state of the art object recognition algorithms are blazing fast and incredibly accurate, allowing you to instantly make real time decisions based on what you see.'
    },
    {
        title: 'Facial Detection',
        text: 'Ficiali can provide you a service for in-house Face Recognition Technology that leverages the power of Artificial Intelligence and Computer Vision Algorithms to provide you state-of-the-art recognition from just a single image of an individual.'
    },
    {
        title: 'Speech Recognition',
        text: 'Ficiali builds Speech Recognition for Enterprisecustomers. Powered by our own Deep Learning models, our scalable API reliably translates high-value audio into parsable data with industry leading accuracy.'
    },
    {
        title: 'Optical Character Recognition',
        text: "Ficiali’s OCR API helps digitize documents, extract and organize data from credit cards, passports, driver's licenses and tax receipts without lifting a finger. OCR from Ficiali organizes and streamlines the data capture process so that you don’t have to."
    },      
]

const Products = () => {
    return (
        <div className='ficiali__products section__padding'>
          <div className='ficiali__products-heading'>
              <h1 className='gradient__text'>Our Expertise</h1>
              <p>We are the global hub for AI services. The team of experts helps you make innovative solutions to new technology at Ficiali.</p>
              <p>Let's Invest in AI for a better future. </p>

          </div>
          <div className='ficiali__products-container'>
            {productsData.map( (item, index) => (
                <Feature line title={item.title} text={item.text} key={item.title + index}/>
            ))}
          </div>
        </div>
    )
}

export default Products
 