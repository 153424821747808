import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Divider } from '@mui/material';
import { GoDotFill as CheckCircleOutline } from "react-icons/go";
import { Link } from "react-router-dom"
import Image from "../assets/mobile-dev.webp"
import { getWindowSize } from '../utils/getWindowSize';

const textColor = "#81AFDD"
const headingColor = "#E9E4D4"

const additionalServices = [
    {
        name: "Machine Learning",
        path: "/services/machine-learning"
    },
    {
        name: "Computer Vision",
        path: "/services/computer-vision"
    },
    {
        name: "DeepLearning",
        path: "/services/deep-learning"
    },
    {
        name: "Data Analytics",
        path: "/services/data-analytics"
    },
    {
        name: "Web Development",
        path: "/services/web-development"
    },
    // {
    //     name: "Mobile Development",
    //     path: "/services/mobile-development"
    // },
]

const details = {
    name: "Mobile Development",
    imageUrl: Image,
    paragraph1: "Mobile Development refers to the process of creating mobile applications for smartphones and tablets. It involves designing, coding, testing, and deploying software applications that run on mobile devices. In today's mobile-centric world, mobile app development is essential for businesses and individuals looking to reach a wider audience and provide valuable services on the go.",
    additionalDetails: [
        {
            name: "What We Offer",
            points: [
                "Customized mobile app development for iOS and Android platforms.",
                "User-friendly and responsive design for seamless mobile experiences.",
                "Integration with backend services, databases, and cloud solutions."
            ]
        },
        {
            name: "Why Choose Us",
            points: [
                "A dedicated team of experienced mobile app developers and designers.",
                "Proven expertise in creating intuitive, high-performance, and scalable mobile apps.",
                "Adherence to best practices in user experience (UX) and app security."
            ]
        }
    ],
    applications: {
        subtitle: "Mobile Development has a wide range of applications, including but not limited to:",
        points: [
            {
                heading: "E-commerce",
                description: "Creating mobile shopping apps for online retail businesses."
            },
            {
                heading: "Social Networking",
                description: "Building social media apps for connecting people and sharing content."
            },
            {
                heading: "Health and Fitness",
                description: "Developing fitness tracking apps, wellness, and health monitoring solutions."
            },
            {
                heading: "Finance",
                description: "Creating mobile banking, payment, and investment apps for financial institutions."
            }
        ]
    }
};






const MobileDevelopmentServicesPage = () => {
        
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <div className="gradient__bg"> 
    <Container maxWidth="lg" sx={{ paddingTop: 6, paddingBottom: 10 }}>
      <Grid container spacing={3} sx={{ gap: 10 }}>
        {/* Left Column - Image */}
        <Grid item xs={12}>
          <Box elevation={3}>
                <img
                src={details?.imageUrl}
                alt={details?.name}
                style={{ 
                    width: windowSize.innerWidth > 786 ? '80%': '100%',
                    maxWidth: '100%',
                    height: windowSize.innerWidth > 600 ? '50vh': '20vh',
                    borderRadius: 8,
                }}
                />
            </Box>
          <Box my={4}>
            <Typography sx={{ color: headingColor }} variant="h4" gutterBottom>
                {details?.name}
            </Typography>
            <Typography sx={{ color: textColor, lineHeight: 2, mb: 6 }} variant="body1">
               {details?.paragraph1}
            </Typography>

            {details?.paragraph2 &&
             <Typography sx={{ color: textColor }} variant="body1">
                {details?.paragraph2}
            </Typography>
            }

            {details?.additionalDetails?.map((item, index) => (
                <Box my={4} key={index}>
                <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                   {item?.name}
                </Typography>
                <List>
                    {item?.points?.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleOutline color={textColor} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: textColor }} primary={point} />
                        </ListItem>
                    ))}
                </List>
                </Box>
            ))}
           

            {/* Additional Services */}
            <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                {details?.name} Applications
            </Typography>
            <Typography sx={{ color: textColor }}  gutterBottom>
                {details?.applications?.subtitle}
            </Typography>
            <List>
                {details?.applications?.points?.map((point, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CheckCircleOutline color={textColor} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: textColor }} primary={`${point?.heading}: ${point?.description}`} />
                    </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

export default MobileDevelopmentServicesPage;
