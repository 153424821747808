import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Divider } from '@mui/material';
import { GoDotFill as CheckCircleOutline } from "react-icons/go";
import { Link } from "react-router-dom"
import Image from "../assets/data-analytics.jpeg"
import { getWindowSize } from '../utils/getWindowSize';

const textColor = "#81AFDD"
const headingColor = "#E9E4D4"

const additionalServices = [
    {
        name: "Machine Learning",
        path: "/services/machine-learning"
    },
    {
        name: "Computer Vision",
        path: "/services/computer-vision"
    },
    {
        name: "DeepLearning",
        path: "/services/deep-learning"
    },
    // {
    //     name: "Data Analytics",
    //     path: "/services/data-analytics"
    // },
    {
        name: "Web Development",
        path: "/services/web-development"
    },
    {
        name: "Mobile Development",
        path: "/services/mobile-development"
    },
]

const details = {
    name: "Data Analytics",
    imageUrl: Image,
    paragraph1: "Data Analytics is the process of examining large datasets to uncover meaningful insights, trends, and patterns. It involves the use of various techniques and tools to transform raw data into actionable information for informed decision-making. Data Analytics plays a crucial role in today's data-driven world, assisting organizations in optimizing operations, improving customer experiences, and gaining a competitive edge.",
    additionalDetails: [
        {
            name: "What We Offer",
            points: [
                "Customized Data Analytics solutions to address your specific business goals.",
                "Data collection, cleansing, and preparation to ensure data quality.",
                "Advanced statistical analysis, data visualization, and predictive modeling."
            ]
        },
        {
            name: "Why Choose Us",
            points: [
                "A team of skilled Data Analysts and Data Scientists with domain expertise.",
                "Proven experience in extracting actionable insights from complex datasets.",
                "Commitment to data security, privacy, and compliance with regulations."
            ]
        }
    ],
    applications: {
        subtitle: "Data Analytics finds applications across various domains, including:",
        points: [
            {
                heading: "Business Intelligence",
                description: "Enables data-driven decision-making, performance tracking, and market analysis."
            },
            {
                heading: "E-commerce",
                description: "Utilized for customer segmentation, recommendation engines, and sales forecasting."
            },
            {
                heading: "Healthcare",
                description: "Helps in patient outcomes analysis, cost reduction, and medical research."
            },
            {
                heading: "Finance",
                description: "Used for risk assessment, fraud detection, and investment analysis."
            }
        ]
    }
};

const DataAnalyticsServicePage = () => {

    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <div className="gradient__bg"> 
    <Container maxWidth="lg" sx={{ paddingTop: 6, paddingBottom: 10 }}>
      <Grid container spacing={3} sx={{ gap: 10 }}>
        {/* Left Column - Image */}
        <Grid item xs={12}>
          <Box elevation={3}>
                <img
                    src={details?.imageUrl}
                    alt={details?.name}
                    style={{ 
                        width: windowSize.innerWidth > 786 ? '80%': '100%',
                        maxWidth: '100%',
                        height: windowSize.innerWidth > 600 ? '50vh': '20vh',
                        borderRadius: 8,
                    }}
                />
            </Box>
          <Box my={4}>
            <Typography sx={{ color: headingColor }} variant="h4" gutterBottom>
                {details?.name}
            </Typography>
            <Typography sx={{ color: textColor, lineHeight: 2, mb: 6 }} variant="body1">
               {details?.paragraph1}
            </Typography>

            {details?.paragraph2 &&
             <Typography sx={{ color: textColor }} variant="body1">
                {details?.paragraph2}
            </Typography>
            }

            {details?.additionalDetails?.map((item, index) => (
                <Box my={4} key={index}>
                <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                   {item?.name}
                </Typography>
                <List>
                    {item?.points?.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleOutline color={textColor} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: textColor }} primary={point} />
                        </ListItem>
                    ))}
                </List>
                </Box>
            ))}
           

            {/* Additional Services */}
            <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                {details?.name} Applications
            </Typography>
            <Typography sx={{ color: textColor }}  gutterBottom>
                {details?.applications?.subtitle}
            </Typography>
            <List>
                {details?.applications?.points?.map((point, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CheckCircleOutline color={textColor} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: textColor }} primary={`${point?.heading}: ${point?.description}`} />
                    </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

export default DataAnalyticsServicePage;
