import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Divider } from '@mui/material';
import { GoDotFill as CheckCircleOutline } from "react-icons/go";
import { Link } from "react-router-dom"
import Image from "../assets/machine-learning.jpg"
import { getWindowSize } from '../utils/getWindowSize';

const textColor = "#81AFDD"
const headingColor = "#E9E4D4"

const additionalServices = [
    // {
    //     name: "Machine Learning",
    //     path: "/services/machine-learning"
    // },
    {
        name: "Computer Vision",
        path: "/services/computer-vision"
    },
    {
        name: "DeepLearning",
        path: "/services/deep-learning"
    },
    {
        name: "Data Analytics",
        path: "/services/data-analytics"
    },
    {
        name: "Web Development",
        path: "/services/web-development"
    },
    {
        name: "Mobile Development",
        path: "/services/mobile-development"
    },
]

const details = {
    name: "Machine Learning",
    imageUrl: Image,
    paragraph1: "Machine Learning, a branch of artificial intelligence, empowers computers to learn from data and improve their performance on tasks, making it a driving force in today's digital landscape. This technology underpins everything from voice assistants and recommendation systems to healthcare diagnostics and autonomous vehicles. As it continues to evolve, machine learning holds the promise of unlocking deeper insights, automating complex processes, and revolutionizing industries, while also prompting important discussions about fairness, accountability, and the ethical use of data-driven algorithms in our increasingly interconnected world.",
    additionalDetails: [
        {
            name: "What We Offer",
            points: [
                "Custom Machine Learning Solutions tailored to your business needs.",
                "Data Analysis and Modeling to derive valuable insights.",
                "Deep Learning and Neural Networks for complex tasks."
            ]
        },
        {
            name: "Why Choose Us",
            points: [
                "Experienced Data Scientists with a proven track record.",
                "Cutting-Edge Technology and tools for effective solutions.",
                "Successful case studies and satisfied clients."
            ]
        }
    ],
    applications: {
            subtitle: "Machine learning has a wide range of applications across industries, including:",
            points: [
                {
                    heading: "Natural Language Processing (NLP)",
                    description: "ML powers chatbots, language translation, sentiment analysis, and voice recognition."
                },
                {
                    heading: "Healthcare",
                    description: "ML assists in disease diagnosis, drug discovery, and predicting patient outcomes."
                },
                {
                    heading: "Finance",
                    description: "ML is used for fraud detection, credit scoring, and stock market analysis."
                },
                {
                    heading: "Manufacturing",
                    description: "ML optimizes production processes, reducing defects and downtime."
                },
            ]
        }
}

const MachineLearningServicePage = () => {
        
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <div className="gradient__bg"> 
    <Container maxWidth="lg" sx={{ paddingTop: 6, paddingBottom: 10 }}>
      <Grid container spacing={3} sx={{ gap: 10 }}>
        {/* Left Column - Image */}
        <Grid item xs={12}>
          <Box elevation={3}>
                <img
                    src={details?.imageUrl}
                    alt={details?.name}
                    style={{ 
                        width: windowSize.innerWidth > 786 ? '80%': '100%',
                        maxWidth: '100%',
                        height: windowSize.innerWidth > 600 ? '50vh': '20vh',
                        borderRadius: 8,
                    }}
                />
            </Box>
          <Box my={4}>
            <Typography sx={{ color: headingColor }} variant="h4" gutterBottom>
                {details?.name}
            </Typography>
            <Typography sx={{ color: textColor, lineHeight: 2 }} variant="body1">
               {details?.paragraph1}
            </Typography>

            {details?.paragraph2 &&
             <Typography sx={{ color: textColor }} variant="body1">
                {details?.paragraph2}
            </Typography>
            }

            {details?.additionalDetails?.map((item, index) => (
                <Box my={4} key={index}>
                <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                   {item?.name}
                </Typography>
                <List>
                    {item?.points?.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleOutline color={textColor} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: textColor }} primary={point} />
                        </ListItem>
                    ))}
                </List>
                </Box>
            ))}
           
            {/* Additional Services */}
            <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                {details?.name} Applications
            </Typography>
            <Typography sx={{ color: textColor }}  gutterBottom>
                {details?.applications?.subtitle}
            </Typography>
            <List>
                {details?.applications?.points?.map((point, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CheckCircleOutline color={textColor} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: textColor }} primary={`${point?.heading}: ${point?.description}`} />
                    </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

export default MachineLearningServicePage;
