import React from 'react';
import './feature.css';
import { Link } from 'react-router-dom';

const Feature = ({ line, Icon, title, text, href }) => (
  <Link to={href} className="ficiali__features-container__feature">
    <div className="ficiali__features-container__feature-title" style={{ display: Icon ? 'flex' : ''}}>
      {line && (<div />) } 
      {Icon && (<Icon color="#FFF" size={27} />) } 
        <h1 style={{marginLeft: Icon ? '0.6rem': '0rem'}}>{title}</h1>
    </div>
    <div className="ficiali__features-container_feature-text">
      <p>{text}</p>
    </div>
  </Link>
);

export default Feature;