import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Divider } from '@mui/material';
import { GoDotFill as CheckCircleOutline } from "react-icons/go";
import { Link } from "react-router-dom"
import Image from "../assets/computer-vision.jpg"
import { getWindowSize } from '../utils/getWindowSize';

const textColor = "#81AFDD"
const headingColor = "#E9E4D4"

const additionalServices = [
    {
        name: "Machine Learning",
        path: "/services/machine-learning"
    },
    // {
    //     name: "Computer Vision",
    //     path: "/services/computer-vision"
    // },
    {
        name: "DeepLearning",
        path: "/services/deep-learning"
    },
    {
        name: "Data Analytics",
        path: "/services/data-analytics"
    },
    {
        name: "Web Development",
        path: "/services/web-development"
    },
    {
        name: "Mobile Development",
        path: "/services/mobile-development"
    },
]

const details = {
    name: "Computer Vision",
    imageUrl: Image,
    paragraph1: "Computer Vision is a field of artificial intelligence that focuses on enabling computers to interpret and understand visual information from the world, much like human vision. It involves the development of algorithms and models to process, analyze, and make decisions based on images and videos. Computer Vision has a broad range of applications across various industries and plays a crucial role in technologies such as facial recognition, autonomous vehicles, medical image analysis, and more.",
    additionalDetails: [
        {
            name: "What We Offer",
            points: [
                "Custom Computer Vision Solutions tailored to your specific needs.",
                "Object Detection, Image Classification, and Video Analysis capabilities.",
                "Integration with existing systems and hardware for seamless deployment."
            ]
        },
        {
            name: "Why Choose Us",
            points: [
                "Expertise in cutting-edge Computer Vision algorithms and techniques.",
                "A team of experienced Computer Vision engineers and researchers.",
                "Proven success in delivering real-world Computer Vision solutions."
            ]
        }
    ],
    applications: {
        subtitle: "Computer Vision finds applications in various domains, including:",
        points: [
            {
                heading: "Autonomous Vehicles",
                description: "Computer Vision enables self-driving cars to perceive and navigate the environment safely."
            },
            {
                heading: "Medical Imaging",
                description: "In healthcare, it aids in the diagnosis of diseases from medical images like X-rays and MRIs."
            },
            {
                heading: "Retail",
                description: "Used for cashier-less stores, inventory management, and customer behavior analysis."
            },
            {
                heading: "Security and Surveillance",
                description: "Facial recognition and anomaly detection for enhanced security."
            }
        ]
    }
};


const ComputerVisionServicePage = () => {
    
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <div className="gradient__bg"> 
    <Container maxWidth="lg" sx={{ paddingTop: 6, paddingBottom: 10 }}>
      <Grid container spacing={3} sx={{ gap: 10 }}>
        {/* Left Column - Image */}
        <Grid item xs={12}>
          <Box elevation={3}>
                <img
                    src={details?.imageUrl}
                    alt={details?.name}
                    style={{ 
                        width: windowSize.innerWidth > 786 ? '80%': '100%',
                        maxWidth: '100%',
                        height: windowSize.innerWidth > 600 ? '50vh': '20vh',
                        borderRadius: 8,
                    }}
                />
            </Box>
          <Box my={4}>
            <Typography sx={{ color: headingColor }} variant="h4" gutterBottom>
                {details?.name}
            </Typography>
            <Typography sx={{ color: textColor, lineHeight: 2, mb: 6 }} variant="body1">
               {details?.paragraph1}
            </Typography>

            {details?.paragraph2 &&
             <Typography sx={{ color: textColor }} variant="body1">
                {details?.paragraph2}
            </Typography>
            }

            {details?.additionalDetails?.map((item, index) => (
                <Box my={4} key={index}>
                <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                   {item?.name}
                </Typography>
                <List>
                    {item?.points?.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleOutline color={textColor} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: textColor }} primary={point} />
                        </ListItem>
                    ))}
                </List>
                </Box>
            ))}
           

            {/* Additional Services */}
            <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                {details?.name} Applications
            </Typography>
            <Typography sx={{ color: textColor }}  gutterBottom>
                {details?.applications?.subtitle}
            </Typography>
            <List>
                {details?.applications?.points?.map((point, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CheckCircleOutline color={textColor} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: textColor }} primary={`${point?.heading}: ${point?.description}`} />
                    </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};



export default ComputerVisionServicePage;
