import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Divider } from '@mui/material';
import { GoDotFill as CheckCircleOutline } from "react-icons/go";
import { Link } from "react-router-dom"
import Image from "../assets/deep-learning.jpeg"
import { getWindowSize } from '../utils/getWindowSize';

const textColor = "#81AFDD"
const headingColor = "#E9E4D4"

const additionalServices = [
    {
        name: "Machine Learning",
        path: "/services/machine-learning"
    },
    {
        name: "Computer Vision",
        path: "/services/computer-vision"
    },
    // {
    //     name: "DeepLearning",
    //     path: "/services/deep-learning"
    // },
    {
        name: "Data Analytics",
        path: "/services/data-analytics"
    },
    {
        name: "Web Development",
        path: "/services/web-development"
    },
    {
        name: "Mobile Development",
        path: "/services/mobile-development"
    },
]

const details = {
    name: "Deep Learning",
    imageUrl: Image,
    paragraph1: "Deep Learning is a subset of machine learning that focuses on neural networks with multiple layers, also known as artificial neural networks. It has gained significant attention and popularity due to its ability to automatically learn and represent complex patterns and features from data. Deep Learning has revolutionized various fields, including computer vision, natural language processing, and speech recognition, leading to remarkable advancements in technology and applications.",
    additionalDetails: [
        {
            name: "What We Offer",
            points: [
                "Custom Deep Learning models tailored to your specific data and tasks.",
                "Expertise in Convolutional Neural Networks (CNNs) for image analysis.",
                "Recurrent Neural Networks (RNNs) for sequential data and Natural Language Processing (NLP)."
            ]
        },
        {
            name: "Why Choose Us",
            points: [
                "A team of experienced Deep Learning engineers and researchers.",
                "Proven track record in solving complex problems with Deep Learning.",
                "Dedication to staying updated with the latest advancements in the field."
            ]
        }
    ],
    applications: {
        subtitle: "Deep Learning has a wide range of applications, including but not limited to:",
        points: [
            {
                heading: "Computer Vision",
                description: "Used for image and video analysis, object detection, and facial recognition."
            },
            {
                heading: "Natural Language Processing",
                description: "Enables language translation, sentiment analysis, chatbots, and text generation."
            },
            {
                heading: "Autonomous Systems",
                description: "Powering self-driving cars, drones, and robotics for navigation and decision-making."
            },
            {
                heading: "Healthcare",
                description: "Assisting in disease diagnosis, medical image analysis, and drug discovery."
            }
        ]
    }
};


const DeepLearningServicesPage = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


  return (
    <div className="gradient__bg"> 
    <Container maxWidth="lg" sx={{ paddingTop: 6, paddingBottom: 10 }}>
      <Grid container spacing={3} sx={{ gap: 10 }}>
        {/* Left Column - Image */}
        <Grid item xs={12}>
          <Box elevation={3}>
                <img
                    src={details?.imageUrl}
                    alt={details?.name}
                    style={{ 
                        width: windowSize.innerWidth > 786 ? '80%': '100%',
                        maxWidth: '100%',
                        height: windowSize.innerWidth > 600 ? '50vh': '20vh',
                        borderRadius: 8,
                    }}
                />
            </Box>
          <Box my={4}>
            <Typography sx={{ color: headingColor }} variant="h4" gutterBottom>
                {details?.name}
            </Typography>
            <Typography sx={{ color: textColor, lineHeight: 2, mb: 6 }} variant="body1">
               {details?.paragraph1}
            </Typography>

            {details?.paragraph2 &&
             <Typography sx={{ color: textColor }} variant="body1">
                {details?.paragraph2}
            </Typography>
            }

            {details?.additionalDetails?.map((item, index) => (
                <Box my={4} key={index}>
                <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                   {item?.name}
                </Typography>
                <List>
                    {item?.points?.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleOutline color={textColor} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: textColor }} primary={point} />
                        </ListItem>
                    ))}
                </List>
                </Box>
            ))}
           

            {/* Additional Services */}
            <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                {details?.name} Applications
            </Typography>
            <Typography sx={{ color: textColor }}  gutterBottom>
                {details?.applications?.subtitle}
            </Typography>
            <List>
                {details?.applications?.points?.map((point, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CheckCircleOutline color={textColor} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: textColor }} primary={`${point?.heading}: ${point?.description}`} />
                    </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

export default DeepLearningServicesPage;
