import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import logo from '../../assets/FicialiLogo.png'
import './navbar.css'

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false)

    const Menu =  () => (
        <>
            <p><a href='/#home'>Home</a></p>
            <p><a href='/#about'>About</a></p>
            <p><a href='/#services'>Services</a></p>
            <p><a href='/#projects'>Portfolio</a></p>
            <p><a href='/#contact'>Contact</a></p>
        </>
    ) 

    return (
        <div className="gradient__bg"> 
            <div className='ficiali__navbar'>
                <div className='ficiali__navbar-links'>
                    <div >
                            <a href="/" className='ficiali__navbar-links_logo'>
                                <img src={logo} alt='logo' width={60} height={80}/> 
                                <h1>Ficiali</h1>
                            </a>
                    
                    </div>
                    <div className='ficiali__navbar-links_container'>
                        <Menu/>
                    </div>
                    <div className='ficiali__navbar-menu'>
                        {toggleMenu 
                            ?  <RiCloseLine color='#fff' size={27}  onClick={() => setToggleMenu(false)} />
                            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
                        }   
                        {toggleMenu && (
                            <div className='ficiali__navbar-menu_container scale-up-center'>
                                <div className='ficiali__navbar-menu_containter-links'>
                                    <Menu />
                                </div>
                            </div> 
                        )

                        }
                    </div>

                </div>
            </div>
            </div>
    )
}

export default Navbar
