import React from 'react'
import { goalytics, stitchit, uya, health, vcode, solidus, nova, topshop, formAI, barbarly, iCast, nature } from './imports' 
import './partner.css'

const Partner = () => {
    return (
        <div className='section__padding'>
                <div className="ficiali__partner-heading">
                        <h1 className="gradient__text">Our Clients</h1>
                </div>
                <div className='ficiali__partner '>
                        <div>
                                <img className='ficiali__partner-img' src={stitchit} alt='stitchit' width={160} height={90}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={solidus} alt='solidus' width={160} height={60}/>
                        </div>
                      
                        <div>
                                <img className='ficiali__partner-img' src={uya} alt='uya' width={160} height={78}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={health} alt='health' width={160} height={64}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={topshop} alt='thetopshop' width={180} height={64} style={{marginLeft: '-12px'}}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={vcode} alt='vcode' width={160} height={60}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={goalytics} alt='goalytics' width={154} height={154}/>
                        </div>
                    
                        <div>
                                <img className='ficiali__partner-img' src={nova} alt='nova' width={160} height={46} style={{marginLeft: '20px'}}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={barbarly} alt='Barbarly' width={168} height={68} style={{marginLeft: '20px', marginTop: "10px", marginBottom: "30px"}}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={formAI} alt='FormAI' width={168} height={54} style={{marginLeft: '10px', marginTop: "10px", marginBottom: "30px"}}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={iCast} alt='iCast' width={160} height={72} style={{marginLeft: '10px', marginTop: "10px", marginBottom: "30px"}}/>
                        </div>
                        <div>
                                <img className='ficiali__partner-img' src={nature} alt='Nature' width={160} height={54} style={{marginLeft: '10px', marginTop: "10px", marginBottom: "30px"}}/>
                        </div>
                </div>
        </div>
    )
}

export default Partner
