import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Grid, Box, Divider } from '@mui/material';
import { GoDotFill as CheckCircleOutline } from "react-icons/go";
import { Link } from "react-router-dom"
import Image from "../assets/web-dev.webp"
import { getWindowSize } from '../utils/getWindowSize';

const textColor = "#81AFDD"
const headingColor = "#E9E4D4"

const additionalServices = [
    {
        name: "Machine Learning",
        path: "/services/machine-learning"
    },
    {
        name: "Computer Vision",
        path: "/services/computer-vision"
    },
    {
        name: "DeepLearning",
        path: "/services/deep-learning"
    },
    {
        name: "Data Analytics",
        path: "/services/data-analytics"
    },
    // {
    //     name: "Web Development",
    //     path: "/services/web-development"
    // },
    {
        name: "Mobile Development",
        path: "/services/mobile-development"
    },
]

const details = {
    name: "Web Development",
    imageUrl: Image,
    paragraph1: "Web Development is the process of creating and maintaining websites or web applications. It encompasses a range of tasks, including web design, coding, content creation, and server management. In today's digital age, a strong online presence is essential for businesses and individuals, making web development a critical aspect of modern life.",
    additionalDetails: [
        {
            name: "What We Offer",
            points: [
                "Customized website and web application development tailored to your needs.",
                "Responsive web design for optimal viewing on various devices and screen sizes.",
                "Backend development, database integration, and content management systems (CMS)."
            ]
        },
        {
            name: "Why Choose Us",
            points: [
                "A team of skilled web developers and designers with extensive experience.",
                "Proven track record in delivering user-friendly, visually appealing, and functional websites.",
                "Adherence to web standards, security best practices, and SEO optimization."
            ]
        }
    ],
    applications: {
        subtitle: "Web Development is utilized for various purposes, including:",
        points: [
            {
                heading: "E-commerce",
                description: "Creating online stores and marketplaces for selling products and services."
            },
            {
                heading: "Content Management",
                description: "Building blogs, news websites, and content-driven platforms."
            },
            {
                heading: "Portfolio and Personal Websites",
                description: "Showcasing individual or professional work and skills."
            },
            {
                heading: "Web Applications",
                description: "Developing interactive web-based software for specific tasks or services."
            }
        ]
    }
};



const WebDevelopmentServicesPage = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

  return (
    <div className="gradient__bg"> 
    <Container maxWidth="lg" sx={{ paddingTop: 6, paddingBottom: 10 }}>
      <Grid container spacing={3} sx={{ gap: 10 }}>
        {/* Left Column - Image */}
        <Grid item xs={12}>
          <Box elevation={3}>
                <img
                    src={details?.imageUrl}
                    alt={details?.name}
                    style={{ 
                        width: windowSize.innerWidth > 786 ? '80%': '100%',
                        maxWidth: '100%',
                        height: windowSize.innerWidth > 600 ? '50vh': '20vh',
                        borderRadius: 8,
                    }}
                />
            </Box>
          <Box my={4}>
            <Typography sx={{ color: headingColor }} variant="h4" gutterBottom>
                {details?.name}
            </Typography>
            <Typography sx={{ color: textColor, lineHeight: 2 }} variant="body1">
               {details?.paragraph1}
            </Typography>

            {details?.paragraph2 &&
             <Typography sx={{ color: textColor }} variant="body1">
                {details?.paragraph2}
            </Typography>
            }

            {details?.additionalDetails?.map((item, index) => (
                <Box my={4} key={index}>
                <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                   {item?.name}
                </Typography>
                <List>
                    {item?.points?.map((point, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleOutline color={textColor} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: textColor }} primary={point} />
                        </ListItem>
                    ))}
                </List>
                </Box>
            ))}
           

            {/* Additional Services */}
            <Typography sx={{  color: headingColor }} variant="h5" gutterBottom>
                {details?.name} Applications
            </Typography>
            <Typography sx={{ color: textColor }}  gutterBottom>
                {details?.applications?.subtitle}
            </Typography>
            <List>
                {details?.applications?.points?.map((point, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CheckCircleOutline color={textColor} />
                        </ListItemIcon>
                        <ListItemText sx={{ color: textColor }} primary={`${point?.heading}: ${point?.description}`} />
                    </ListItem>
                ))}
            </List>
          </Box>
        </Grid>

        {/* Right Column - Content */}
        {/* <Grid item xs={12} md={3}>
        <Paper 
            sx={{ 
                backgroundColor: "transparent",
                border: 1,
                borderColor: textColor,
                borderRadius: 2,
                // padding: 3
            }}>
            <Typography sx={{  color: headingColor, textAlign: "center", py: 1 }} variant="h6" gutterBottom>
                Additional Services
            </Typography>
            <Divider sx={{ backgroundColor: textColor }} />
            <List>
                {additionalServices.map((item) => (
                    <Box key={item.path} my={2}>
                        <Link to={item.path} >
                            <ListItem>
                                <ListItemText sx={{ color: textColor }} primary={item.name} />
                            </ListItem>
                        </Link>
                    </Box>
                ))}
            </List>
        </Paper>
        </Grid> */}
      </Grid>
    </Container>
    </div>
  );
};

export default WebDevelopmentServicesPage;
