import { styled, Box, Container, Typography } from "@mui/material";
import React from "react";

const Details = () => {
const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(8, 0, 3, 0),
    margin: theme.spacing(0, 2, 0, 2),
  }));

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "54px",
    color: "#81AFDD",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    color: "#7B8087",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    // marginTop: theme.spacing(0),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 5, 0, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(5),
    },
  }));

  return (
        <CustomBox>
            <Container>
                <TextFlexbox>
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <LargeText>35+</LargeText>
                    <SmallText>Completed Projects</SmallText>
                </Box>

                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <LargeText>30+</LargeText>
                    <SmallText>Happy Clients</SmallText>
                </Box>

                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <LargeText>4+</LargeText>
                    <SmallText>Years Experience</SmallText>
                </Box>
                </TextFlexbox>
            </Container>
        </CustomBox>
  );
};

export default Details;