import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom"
import { useContext, useEffect } from "react"
import './App.css'

import {  Footer } from './containers'
import { Navbar } from "./components";
import Home from "./screens/Home";

import MachineLearningServices from './screens/MachineLearningServices'
import ComputerVisionServicePage from "./screens/ComputerVisionServices"
import DeepLearningServicePage from "./screens/DeepLearningServices"
import DataAnalyticsServicePage from "./screens/DataAnalyticsServices";
import WebDevelopmentServicesPage from "./screens/WebDevelopmentServices";
import MobileDevelopmentServicesPage from "./screens/MobileDevelopmentServices";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if(location.pathname !== "/") {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  } 
  }, [location]);

  return <>{props.children}</>
}


function App() {
  // const location = useLocation();
  const routes = [
    // {
    //   path: "/login",
    //   element: user ? <Navigate to="/" /> : <Login />
    // },
    {
      path: "/",
      element: <Home /> 
    },
    {
      path: "/services/machine-learning",
      element: <MachineLearningServices /> 
    },
    {
      path: "/services/computer-vision",
      element: <ComputerVisionServicePage /> 
    },
    {
      path: "/services/deep-learning",
      element: <DeepLearningServicePage /> 
    },
    {
      path: "/services/data-analytics",
      element: <DataAnalyticsServicePage /> 
    },
    {
      path: "/services/web-development",
      element: <WebDevelopmentServicesPage /> 
    },
    {
      path: "/services/mobile-development",
      element: <MobileDevelopmentServicesPage /> 
    },
    {
      path: "*",
      element: (
        <h1>Not Found</h1>
     )
    },
  ];

 return (
    <div className="App">
        <Navbar />
        <ScrollToTop>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  )
}

export default App

