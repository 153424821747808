import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatFiciali.css';
import { FaCogs } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";  
import { FaReact } from "react-icons/fa";  
import { FaRegChartBar } from "react-icons/fa";   
import { FaLaptopCode } from "react-icons/fa";   
import { FaMobileAlt } from "react-icons/fa";   

import video from '../../assets/video.mp4'
import { Link } from 'react-router-dom';


const Whatficiali = () => (
  <div className="ficiali__whatFiciali section__margin" id="about">
     <div className="ficiali__whatFiciali-intro-container">
       <div>
        <Feature line title="About Ficiali" text="At FICIALI, we believe in breaking the barriers raised by traditional technology overheads. We commit to our customers’ IT Strategy and help meet their technology goals by providing expert IT services." />
        <Feature line title="Our Mission" text="The mission is to reshape the World by developing and adopting latest, cutting-edge technologies. Experts are calling this the 4th industrial revolution. We are a global hub for Software Services." /> 
        <Feature line title="Our Vision" text="Our vision is to be the only solution provider, businesses can think of first when they are in need of any technology services to solve complex business problem and exploit the technologies to the best of its capabilities, irrespective of their size." /> 
       </div>
      <div className='ficiali__whatFiciali-intro_video-container'>
        <video controls className='ficiali__whatFiciali-intro_video' >
          <source src={video} type="video/mp4"/>
        </video>
      </div>
     
    </div>
    
    <div className="ficiali__whatFiciali-heading" id="services">
      <h1 className="gradient__text" >What we Offer</h1>
    </div>
    <div className="ficiali__whatFiciali-container">
        <Feature href="/services/machine-learning" Icon={FaCogs} title="Machine Learning" text="Giving machines the ability to automaticllay learn and improve with experience to help execute complex and time exhaustive Predictive Analysis based problems, is what we use Machine Learning Algorithms for." />
        <Feature href="/services/computer-vision" Icon= {FaRegEye} title="Computer Vision" text="We use Computer Vision to train machines to execute Recognition, Identification and Verification tasks leading to automation, using multiple sources of digital images, in a variety of industries." />
        <Feature href="/services/deep-learning" Icon= {FaReact} title="Deep Learning" text="We use Deep Learning to train machines to execute Recognition and Identification tasks leading to automation, sAI function that mimics the workings of the human brain in processing data for use in detecting objects, and making decisions." />
    </div>

    <div className="ficiali__whatFiciali-container">
      <Feature href="/services/data-analytics" Icon={FaRegChartBar} title="Data Analytics" text="Data is a gift to technology and we observe and analyze that data to generate insights to help organizations make better decisions and we perform preditive analysis to identify the possibility of future outcomes based on historical data." />
      <Feature href="/services/web-development" Icon={FaLaptopCode} title="Web Development" text="Our office based in Karachi but we provide web designing services all over World. Let your website visitors have relevant interactions, everlasting and responsive experience with secure and faster search." />
      <Feature href="/services/mobile-development" Icon ={FaMobileAlt} title="Mobile Development" text="The Android devices are growing year over year. You certainly want to offer your unique solution to this wide user base! We develop Android apps for various devices using the Android programming languages and Google’s own development tools and guidelines." />
    </div>
  </div>
);

export default Whatficiali;
