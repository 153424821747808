import React from 'react'
import './project.css'

const Project = ({ imgUrl,  title, text1, text2, text3, text4 }) => {
    return (
        <div className='card'>
        <div className="ficiali__blog-container_article">
            <div className="ficiali__blog-container_article-image">
                <img src={imgUrl} height={200} width={200} alt="blog_image" />
            </div>
            <div className="ficiali__blog-container_article-content">
                <div>
                    <h3>{title}</h3>
                    <p>{text1}</p>
                    {text2 && 
                    <>
                    <br /><p>{text2}</p>
                    </>
                    }
                   {text3 && 
                    <>
                    <br /><p>{text3}</p>
                    </>
                    }
                   {text4 && 
                    <>
                    <br /><p>{text4}</p>
                    </>
                    }
                </div>
                    
            </div>
    </div>
                
    </div>
    )
}

export default Project
