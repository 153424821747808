import React from 'react';
import './footer.css';
import { ContactForm } from '../../components';
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";


const Footer = () => (
  <div className="ficiali__footer section__padding" id="contact">
    <div className="ficiali__footer-links">
      <div className="ficiali__footer-links_div">

        <h4>Social Media Links</h4>
        <div className='ficiali__footer-social-media'>    
          <p><a href="https://m.facebook.com/ficiali/?ref=bookmarks"><FaFacebookSquare size={27} color="#81AFDD"/></a></p>
          <p><a href="https://www.instagram.com/ficiali_official/"><FaInstagramSquare size={27} color="#81AFDD"/></a></p>
          <p><a href="https://www.linkedin.com/company/ficiali"><FaLinkedin size={27} color="#81AFDD"/></a></p>
        </div>

        <div className='ficiali__footer-contact-details'>
        <h4>Get in touch</h4>
            <div><FaMapMarkerAlt size={20} color="#81AFDD"/><p> Pakistan Office: Gulistan e Jauhar Rabia City Block A5 34, Karachi, Sindh, Pakistan.</p></div>
            <div><FaMapMarkerAlt size={20} color="#81AFDD"/><p> UK Office: Nova Studios, Roe Cross Business Park, Mottram, Sk146nb, UK.</p></div>
            <div><FaPhoneAlt size={20} color="#81AFDD"/><p> +923041346726</p> </div>
        </div>
      </div>
      
      <div style={{width: "400px"}}>
        <ContactForm />
      </div>
      
    </div>

    <div className="ficiali__footer-copyright">
        <p>Copyright &copy; Ficiali 2023</p>
    </div>
  </div>
);

export default Footer;