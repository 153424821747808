import React from 'react'
import { Partner, Navbar } from '../components'
import ServiceDetails from './MachineLearningServices'
import { Header, WhatFiciali, Products, Projects, Footer, StatsDetails } from '../containers'

const Home = () => {
  return (
    <div>
     <div className="gradient__bg"> 
        <Header />
      </div>
      <StatsDetails />
      <WhatFiciali />
      <Products />
      <Projects />
      <Partner />
    </div>
  )
}

export default Home