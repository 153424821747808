import React from 'react'
import Project from '../../components/project/Project';
import { goalytics, gait, stitchIt, uya, health, barbarly, quantifyNature, formAI, iCast } from './imports';
import './projects.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      // slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

const Projects = () => {
    return (
        <div className="ficiali__blog section__padding" id="projects">
            <div className="ficiali__blog-heading">
                <h1 className="gradient__text">Recent Projects</h1>
            </div>
              <Carousel 
                showDots={true} 
                responsive={responsive}
                removeArrowOnDeviceType={["tablet", "mobile"]} 
              >
                <Project imgUrl={iCast} title="iCAST (Scan, Create, Cast)"  text1="iCAST is the first patent-pending device that's app controlled allowing you to easily create designs for personal or business use and cast those creations onto one or many surfaces-from this one device." />
                <Project imgUrl={barbarly} title="Barbarly"  text1="Barbarly offers an online booking system through a website and mobile app that allows clients to schedule appointments with the nearest available barbers at their convenience. Clients can easily cancel or reschedule appointments through the platform." />
                <Project imgUrl={formAI} title="FOME.ai"  text1="The FOME.ai team understands how to teach movement to improve performance while minimising damage and errors.  They acknowledge the critical importance of reliable data and feedback in this process." />
                <Project 
                    imgUrl={goalytics} 
                    title="Goalytics Football Player Performance Tracking Data" 
                    text1="Goalytics combines performance and video analysis with the latest developments in artificial intelligence. Which extracts football players performance, defined team color, Bird’s Eye View of Recorded Play, and calculates distance, speed, acceleration, number of shots, and more."
                    // text2 = "The goal is to optimize the performance of every player and the team. It helps you and your team to always keep an eye on your performance."
                    // text4 = "PERFORMANCE + VIDEO + ANALYTICS" 
                    // text3 = "Each video is analyzed using artificial intelligence (neural network) processes. Performance diagnostic and game-related data are obtained. An individual player report with performance-diagnostic and game-related data is created for each player. In order to further develop the teams in their tactical understanding, the video is converted into a bird's eye view. All results are stored in the secure Goalytics Cloud, accessible at any time, and can be viewed a maximum of 48 hours after upload."           
                  />
                  <Project imgUrl={gait} title="Biometric Gait Recognition App" text1="Gait recognition is a kind of biometric technology that can be used to monitor people without their cooperation using video feed;   individuals can be identified by their walking manner!" />
                  <Project imgUrl={stitchIt} title="Stitch It"  text1="It is a platform that allows users to upload the drone images, then stitches those images together to form a panaroma, and finally recognizes and counts the number of objects in it." />
                  <Project imgUrl={health} title="V-Health Passport Covid-19 AI" text1="In this system the Covid-19 test is send to a user via V-Passport Platform. Every test has a unique test ID (U.T.I) which is programmed to only decode correct users (U.U.I.D) via the V-Passport app; Analyse Hughes Healthcare Covid 19 antigen test results using V-Health AI." />
                  <Project imgUrl={uya} title="Understand your Accent" text1="Our goal is to build tool that provided visual feedback to speakers so they could see the difference between what they were trying to say, and what they were actually saying." />
                  <Project imgUrl={quantifyNature} title="Quantifying Nature" text1="The world's first B2B SaaS platform that provides standardized quantification of nature-related risks and impacts, as well as recommendations for nature-friendly solutions." />
              </Carousel>
        </div>
    )
}

export default Projects
